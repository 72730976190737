import './App.css';
import Exercises from './pages/Exercises';

function App() {
  return (
    <div className="App">
      <Exercises/>
    </div>
  );
}

export default App;
