import React, { useEffect } from "react";
import ExercisesList from "../../components/Exercises/ExercisesList";
import { useExercisesList } from "../../hooks/Exercises/useExercisesList";

const Exercises = () => {
  const { exercises } = useExercisesList();

  useEffect(() => {
    console.log("%c⧭", "color: #d90000", exercises);
  }, [exercises]);

  return (
    <>
      {/* <h2>hola</h2> */}
      <ExercisesList exercises={exercises} />
    </>
  );
};

export default Exercises;
